import React from "react"
import { Router } from "@reach/router"
import Dashboard from '../../app/dashboard/index'
import PrivateRoute from "../../components/hoc/PrivateRoute"
import Settings from "../../app/Settings"

const App = (props) => {
  const lang = props.pageContext.intl.language
  console.log(`router was hit: app`);
  return (
    <Router basepath={`/${lang}/settings/`}>
      
{/*       
      <Dashboard path="app/*">
      <Pricing path="pricing" />
      </Dashboard>
       */}

      <PrivateRoute path="/" component={Settings} default/>
      {/* <PrivateRoute path="/app/" component={Dashboard} default />
         */}

      
    </Router>
)}
export default App